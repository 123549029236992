import React from "react"
import styled from "styled-components"
import { Flex } from "../../layout/reusables"
import Img from "gatsby-image"
import Fade from "react-reveal/Fade"
import { Link } from "gatsby"
import { _sluggedName } from "../../../utils/utils"
import { OutlineButton } from "../../styles/buttons"

export default function CollaboratorItem({ item, index, inverse }) {
    const { image, name, channelName, description } = item ?? ""

    const big = inverse ? index === 0 : index === 2

    return (
        <Container inverse={inverse} big={big}>
            <Link style={{textDecoration: 'none', color: 'black'}} to={channelName ? `/${_sluggedName(channelName)}` : null}>
                <ImageContainer big={big}>
                    <Img
                        fluid={image.localFile.childImageSharp.fluid}
                        objectFit="cover"
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            maxHeight: "80vh",
                            // minHeight: "45vh",
                        }}
                    />
                </ImageContainer>
                <Meta direction="column">
                    <Fade>
                        <Label big={big}>
                            <span>{name}</span>
                        </Label>
                        <Label description>{description}</Label>
                        <OutlineButton disabled={!channelName} dark small>{channelName ? 'Go To Channel' : 'Coming Soon'}</OutlineButton>
                    </Fade>
                </Meta>
            </Link>
        </Container>
    )
}

const Container = styled.div`
    position: relative;
    overflow: hidden;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-row: ${props => props.big && "1 / span 2"};
    grid-column: ${props =>
        props.big && (props.inverse ? "1 / span 2" : "3 / span 2")};

    @media (max-width: 768px) {
        grid-column: auto;
        grid-row: auto;
    }

    @media (max-width: 550px) {
        grid-column: auto;
        grid-row: auto;
    }
`
const ImageContainer = styled.div`
    transition: 0.2s ease;
    height: ${props => props.big ? 'auto' : '400px'};

    @media (max-width: 1000px) {
        height: ${props => props.big ? 'auto' : '300px'};
    }
    @media (max-width: 780px) {
        height: 450px;
    }

    ${Container}:hover & {
        filter: brightness(1.4);
        filter: saturate(0.6);
    }
`;
const Meta = styled(Flex)`
    margin-top: 15px;
`
const Label = styled.p`
    color: black;
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 15px;
    transition: 0.2s ease;

    ${Container}:hover & {
        opacity: 0.6;
    }

    span {
        font-size: ${props => (props.big ? "32px" : "24px")};
        font-weight: 500;
        line-height: 1.3;

        @media (max-width: 780px) {
            font-size: 22px;
        }
    }

    @media (max-width: 550px) {
        font-size: 14px;
    }
`
