import { motion } from "framer-motion"
import React, { useState } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { DigestiveText } from "../../styles/typography"
import { Flex } from "../../layout/reusables"
import { colors } from "../../styles/colors"
import Vimeo from "@u-wave/react-vimeo"

const ResourcesHeader = ({ image, eyebrow, title }) => {
    const [playing, setPlaying] = useState(false)

    return (
        <Container>
            <CoverContainer>
                <VideoContainer>
                    {/* <Img
                        fluid={image}
                        objectFit="cover"
                        style={{ width: "100%", height: "100%" }}
                    /> */}
                    <Vimeo
                        width={"100%"}
                        height={480}
                        video="492739484"
                        autoplay
                        // controls={false}
                        responsive
                        showTitle={false}
                        muted={true}
                        onPlay={() => setPlaying(true)}
                        onPause={() => setPlaying(false)}
                    />
                </VideoContainer>
            </CoverContainer>
            <Content direction="column" align="flex-start">
                <Eyebrow>{eyebrow}</Eyebrow>
                <Title
                    transition={{ delay: 0.3, duration: 1 }}
                    initial={{ opacity: 0 }}
                    animate={{
                        opacity: playing ? 0.1 : 1,
                        scaleY: playing ? 0.8 : 1,
                    }}
                    noUppercase
                >
                    {title}
                </Title>
            </Content>
        </Container>
    )
}

export default ResourcesHeader

const Container = styled.div`
    background: black;
    display: flex;
    flex-direction: column;
    color: white;
    align-items: flex-start;
    justify-content: flex-end;
    overflow: hidden;
    position: relative;
    padding: 20px;
    min-height: 95vh;

    @media (max-width: 550px) {
        min-height: 80vh;
    }
`
const VideoContainer = styled.div`
    width: 85vw;

    @media (max-width: 550px) {
        width: 90vw;
        margin-bottom: 15%;
    }
`
const CoverContainer = styled(motion.div)`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    z-index: 1;
    margin: 0;
    height: 100%;
`
const Content = styled(Flex)`
    z-index: 1;
    padding: 5%;
`
const Eyebrow = styled.p`
    color: ${colors.yellow};
    font-size: clamp(18px, 20px);
    text-decoration: uppercase;
`
const Title = styled(DigestiveText)`
    font-size: clamp(60px, 12vw, 120px);
    text-align: left;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
    z-index: 2;
    color: white;
    /* margin-bottom: 40px; */
`
